// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import axios from "axios";
import { createBrowserHistory } from "history";
import React from "react"
import { HISTORY } from "../../../../components/src/common";

export const configJSON = require("./config.js");
const history = createBrowserHistory()
export interface Props {
    navigation: any;
    id: string;

}
interface S {
    dashboardData: any;
    token: string;
    errorMsg: string;
    loading: boolean;
    banner: any;
    creativeList: any;
    hooksList: any;
    songsList: any;
    beatsList: any;
    apiToken: any;
    originalList: any,
    playList: any,
    versesList: any,
    playlistData: any,
    checkType: any,
    selectedPlaylist: any,
    open: boolean,
    userType: any,
    dialogText: any,
    user_id: any,
    selected: any,
    handleAudioVideo: any;
    playPlaylist: boolean;
    bannerData: any;
    window_width: any;
    originalId: any;
    bannerOption:any;
    bannerTrack:any;
}
interface SS {
    id: any;
}

export default class MainContentController extends BlockComponent<Props, S, SS> {
    apiDashboardItemCallId: string = "";
    dashboardApiCallId: string = "";
    apiGetQueryStrinurl: string = "";
    orginalRef: any;
    topBeatRef: any;
    topHooksRef: any;
    topPlaylistRef: any;
    topSongsRef: any;
    topVersesRef: any;
    gethandlepreviewlist: any;
    getsongslists: any;
    getbeatlists: any;
    gethooklists: any;
    getverselists: any;
    getplaylists: any;
    getoriginallists: any;
    getalloriginallists: any;
    getcreativedataList: any;
    getAllBannerList:any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.orginalRef = React.createRef();
        this.topBeatRef = React.createRef();
        this.topHooksRef = React.createRef();
        this.topPlaylistRef = React.createRef();
        this.topSongsRef = React.createRef();
        this.topVersesRef = React.createRef();

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            dashboardData: [],
            errorMsg: "",
            token: "",
            loading: false,
            banner: [],
            creativeList: [],
            hooksList: [],
            songsList: [],
            beatsList: [],
            originalList: [],
            playList: [],
            versesList: [],
            playlistData: [],
            checkType: [],
            selectedPlaylist: [],
            open: false,
            apiToken: localStorage.getItem("token"),
            userType: localStorage.getItem("user_type"),
            user_id: localStorage.getItem("loggedIn_userId"),
            dialogText: "",
            selected: null,
            handleAudioVideo: 0,
            playPlaylist: false,
            bannerData: [],
            window_width: 0,
            originalId: 0,
            bannerOption:"",
            bannerTrack:""
           
        };
        this.windowResized = this.windowResized.bind(this);
        this.updateWindowWidth = this.updateWindowWidth.bind(this);
        
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        window.addEventListener("resize", this.windowResized);
        this.updateWindowWidth();
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener('willFocus', () => {
                this.getToken();
            });
        }
        localStorage.removeItem("selectedTab")
        // if(token){
        // if (creativeDatas && creativeDatas?.length !== 0) {
        //   setcreativeList(creativeDatas)
        // } else {
        //   getCreativeLists(token)

        // }
        // setLoader(true)
        this.setState({loading:true})
        this.getSongsLists()
        this.getbeatsLists()
        this.gethooksLists()
        this.getversesLists()
        this.getplayLists()
        this.getBannerList();
        if (this.state.apiToken) {
            this.getCreativeLists(this.state.apiToken)
        this.getOriginalID();

        }

    }

    handleAllApiCall=async()=>{
        
    }


    updateWindowWidth() {
        const windowWidth = window.innerWidth;
        let columns;

        if (windowWidth <= 1560 && windowWidth >= 1393) {
            columns = 6;
        } else if (windowWidth <= 1393 && windowWidth >= 1088) {
            columns = 5;
        } else if (windowWidth <= 1088 && windowWidth >= 800) {
            columns = 4;
        } else if (windowWidth <= 800 && windowWidth >= 540) {
            columns = 3;
        } else if (windowWidth <= 540 && windowWidth >= 380) {
            columns = 2;
        } else if (windowWidth <= 380) {
            columns = 1;
        }

        this.setState({
            window_width: columns,
        });
    }

    windowResized() {
        let _this = this;
        setTimeout(function () {
            _this.updateWindowWidth();
        }, 500);
    }

    handleClickOpen = () => {
        if (this.state.apiToken && this.state.userType !== "listener") {
          this.setState({ dialogText:"1" });
        } else if (this.state.userType === "listener" && this.state.apiToken) {
            this.setState({ dialogText:"3" });

        } else {
            HISTORY.push('/Welcome')
        }
        
    };

    redirectToLogin=()=>{
        if(this.state.open){
            HISTORY.push('/Welcome')
        }
    }

    setPlaylistData = (data: any) => {
        this.setState({ playlistData: data })
    }
    creativeOnClick = () => {
        this.state.apiToken ? (
            HISTORY.push("/home/Creatives")
        ) :

            this.setState({ open: true })
        this.setState({ dialogText: "2" })

    }
    handleClose = () => {
        this.setState({ open: false })
        // setOpen(false);
    };
    hadleRedirectPlaylist = async (id: any) => {
        if (this.state.apiToken) {
            if (this.state.apiToken && id?.attributes?.artist_id == this.state.user_id) {
                HISTORY.push(`/home/self-view/${id.id}`)
                localStorage.setItem("playlist_type", "Private")
            } else if (this.state.apiToken && id?.attributes?.artist_id !== this.state.user_id) {
                HISTORY.push(`/home/playlist/public-view/${id.id}`)

                localStorage.setItem("playlist_type", "Public")
            } else {
                this.setState({ open: true })
                this.setState({ dialogText: "2" })
            }
        } else {
            this.handleClickOpen();
        }

    }

    getToken = () => {
        const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
        this.send(msg);
    }

    getDashboardData(): boolean {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiDashboardItemCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.dashboardGetUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getBannerList = async () => {
                const header = {
                    "Content-Type": configJSON.dashboarContentType,
                    token: localStorage.getItem('token'),
                };
    
                const requestMessage = new Message(
                    getName(MessageEnum.RestAPIRequestMessage)
                );
    
                this.getAllBannerList = requestMessage.messageId;
    
                requestMessage.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    configJSON.baseUrl + `/bx_block_audiomusic/banners`
                );
    
    
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestHeaderMessage),
                    JSON.stringify(header)
                );
    
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestMethodMessage),
                    configJSON.getAPIMethod
                );
    
                runEngine.sendMessage(requestMessage.id, requestMessage);
                return true;
        
    }


    handleBannerPreview = async (options: any, id: any, track: any) => {
       this.setState({bannerOption:options,bannerTrack:track})
        try {
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.gethandlepreviewlist = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.gethandlepreview}?id=${id}`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error);
        }
       
    }

    getCreativeLists = async (token: any) => {

        try {
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getcreativedataList = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.getcreativedata}`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error);
        }
    }

    //song lists
    getSongsLists = async () => {

        try {
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getsongslists = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.getalltablist}?upload_type=Song`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error);
        }
    }

    //beats lists
    getbeatsLists = async () => {

        try {
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getbeatlists = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.getalltablist}?upload_type=Beat`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error);
        }
    }

    //hooks lists
    gethooksLists = async () => {

        try {
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.gethooklists = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.getalltablist}?upload_type=Hook`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error);
        }


    }
    //verses lists
    getversesLists = async () => {

        try {
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getverselists = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.getalltablist}?upload_type=Verse`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error);
        }


    }

    //play lists
    getplayLists = async () => {

        try {
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getplaylists = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.getplaylistlist}`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error);
        }

    }

    //play lists
    getOriginalID = async () => {
        // try {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getoriginallists = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `${configJSON.getoriginaldata}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
        // } catch (error) {
        //     console.log(error);
        // }


    }


    getallOriginalLists = async (id: any) => {
        try {
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getalloriginallists = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.getalloriginallist}?upload_type=Video&user_id=${id}`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        } catch (error) {
            console.log(error);
        }

    }

    redirectToOriginals = () => {
        localStorage.removeItem("selectedTab")
        const id: any = localStorage.getItem("user_id")
        localStorage.setItem("temp_id", id)
        localStorage.removeItem("user_id")
        localStorage.setItem("user_id", this.state.originalId)
        localStorage.setItem("creatives_screen", "true")
        // await localStorage.removeItem("current_type")
        const user = "original";
        localStorage.setItem("current_type", user);
        HISTORY.push(`/originals/videos?originals_id=${this.state.originalId}`,

        )
        //    window.location.reload()

    }

    handlePreviewList=(responseJson:any)=>{
        
        if (responseJson !== undefined && !responseJson.error) {
            this.setState({ bannerData: responseJson?.data })
            if (this.state.bannerOption == "Watch") {
                HISTORY.push({
                    pathname: "/home/Charts/videos",
                    state: { video_list: this.state.bannerTrack, activeNo: "5", data: responseJson?.data, currentList: this.state.banner ? this.state.banner : [] }
                })
            } else {
                //console.log(this.state, 'banner full view')
                HISTORY.push({
                    pathname: "/home/expand/song/songId=banner",
                    state: {
                        data: responseJson?.data,
    
                    }
                })
            }
        }
    }

    handleCreativeList=(responseJson:any)=>{
        if (responseJson !== undefined && !responseJson.error) {
            this.setState({
                creativeList: responseJson?.data,
                // loading: false
            });
        } else {
            this.setState({ loading: false });
        }
    }

    handleGetSongsList=(responseJson:any)=>{
        if (responseJson !== undefined && !responseJson.error) {
            this.setState({ songsList: responseJson?.data })
        }
    }

    handleBeatList=(responseJson:any)=>{
        if (responseJson !== undefined && !responseJson.error) {
            this.setState({ beatsList: responseJson?.data })
        }
    }

    handleHookList=(responseJson:any)=>{
        if (responseJson !== undefined && !responseJson.error) {
            this.setState({ hooksList: responseJson?.data })

        }
    }

    handleVerseList=(responseJson:any)=>{
        if (responseJson !== undefined && !responseJson.error) {
            this.setState({ versesList: responseJson?.data })

        }
    }

    handleGetPlaylist=(responseJson:any)=>{
        if (responseJson !== undefined && !responseJson.error) {
            this.setState({ playList: responseJson?.data, loading: false })

        } else {
            this.setState({ loading: false })
        }
    }

    handleOriginalList=(responseJson:any)=>{
        if (responseJson !== undefined && !responseJson.error) {
            this.setState({ originalId: responseJson?.data?.id })
            this.getallOriginalLists(responseJson?.data?.id)

        } else {
            this.setState({ loading: false })
        }
    }

    handleAllOriginalList=(responseJson:any)=>{
        if (responseJson !== undefined && !responseJson.error && !responseJson.message) {
            this.setState({
                originalList: responseJson?.data,
                // loading: false
            });
        } else {
            this.setState({ loading: false })
        }
    }

    handleAllBannerList=(responseJson:any)=>{
        this.setState({ banner: responseJson?.data,
            // loading:false 
        })
    }
handleSeeAll=()=>{
    this.setState({ open: true , dialogText: "2"});
}

handleAudioVideoValue=(value:any)=>{
    this.setState({
        handleAudioVideo: value,
      })
}

handleSetSelected=(key:any)=>{
    this.setState({ selected: key });
}

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Received', message)
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
            return;
        }

        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

        if (apiRequestCallId == null) {
            this.parseApiCatchErrorResponse(errorReponse);
            return;
        }
        switch (apiRequestCallId) {
            case this.gethandlepreviewlist:
                this.handlePreviewList(responseJson)
                break;
            case this.getcreativedataList:
                this.handleCreativeList(responseJson) 
                break;
            case this.getsongslists:
               this.handleGetSongsList(responseJson)
                break;
            case this.getbeatlists:
               this.handleBeatList(responseJson)
                break;
            case this.gethooklists:
              this.handleHookList(responseJson) 
                break;

            case this.getverselists:
                this.handleVerseList(responseJson)
                break;
            case this.getplaylists:
               this.handleGetPlaylist(responseJson)
                break;
            case this.getoriginallists:
              this.handleOriginalList(responseJson)
                break;
            case this.getalloriginallists:
              this.handleAllOriginalList(responseJson)
                break;
            case this.getAllBannerList:
                this.handleAllBannerList(responseJson)
                    break;
            default:
                break;
        }
    }

}
// Customizable Area End
// Customizable Area Start
import React from "react";
import "./index.css";
import {Row, Dropdown, Menu } from 'antd';
import { Filter_black, nxzloader, triangle } from "../assets";
import Videos from "./Videos/index.web";
import Songs from "../../../../components/src/CustomChart/Songs/Songs.web";
import 'react-toastify/dist/ReactToastify.css';
import LoginAlert from 'components/src/CustomChart/PopUps/LoginAlert.web'
import ChartController from './ChartController.web';
import { Box } from "@material-ui/core";
import { ChartTemplate } from "components/src/CustomChart/ChartTemplate.web";
import TabList from "components/src/CustomChart/TabList.web";

export default class Charts extends ChartController {

  render() {
    const { songsList, hooksList, beatsList, versesList, playList, Top100List, isPlaylist, handleplay,
      playlistData, loading, alertPopup, open,  dialogText, songIndex, active, filterValue, hideDownloadInPlaylist,itemsToShow
    } = this.state
    const token: any = localStorage.getItem("token")

    const menu = () => {
      return (
        <div>
          <Menu className="custom-drop-menu">
            <Menu.Item key="0" style={{ color: "white", backgroundColor: "transparent", fontFamily: "Poppins" }} onClick={() => this.handleLoginFilter("today")} >Today</Menu.Item>
            <Menu.Item key="1" style={{ color: "white", backgroundColor: "transparent", fontFamily: "Poppins" }} onClick={() => this.handleLoginFilter("this_week")}>This week</Menu.Item>

            <Menu.Item key="3" style={{ color: "white", backgroundColor: "transparent", fontFamily: "Poppins" }} onClick={() => this.handleLoginFilter("this_month")}> This month</Menu.Item>
            <Menu.Item key="4" style={{ color: "white", backgroundColor: "transparent", fontFamily: "Poppins" }} onClick={() => this.handleLoginFilter("this_year")}>This Year</Menu.Item>

          </Menu>
        </div>
      );
    };

    const LoadingScreen = () => (
      <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
        <img src={nxzloader} style={{ width: "5%" }} />
      </Row>
    );

    const handleBeatsList=()=>{
      const visibleBeatsList = beatsList.slice(0, itemsToShow);
      return beatsList?.length === 0 && loading ? (
        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
          <img src={nxzloader} style={{ width: "5%" }} />
        </Row>
      ) : (
        <Songs
          listOfSongs={visibleBeatsList}
          playPlaylist={isPlaylist}
          playlistData={playlistData}
          name="Beats" songIndex={songIndex}
          setDialogText={this.setDialogText}
          setOpen={this.setOpen}
          handleplay={handleplay}
          handleAudioVideo={this.state.handleAudioVideo}
          hideDownloadInPlaylist={false}
          songList={beatsList}
          scrollContainer={this.scrollContainer}
        // setHandleAudioVideo={(value: any) => this.setHandleAudioVideo(value) }
        // setHandlePlay={(value: any) => this.setHandlePlay(value) }

        />
      );
    }

    const handleHookList=()=>{
      return hooksList?.length < 1 && loading ? (
        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
          <img src={nxzloader} style={{ width: "5%" }} />
        </Row>
      ) : (
        <Songs
          listOfSongs={hooksList.slice(0, itemsToShow)}
          playPlaylist={isPlaylist}
          name="Hooks"
          playlistData={playlistData}
          songIndex={songIndex}
          setDialogText={this.setDialogText}
          setOpen={this.setOpen}
          handleplay={handleplay}
          handleAudioVideo={this.state.handleAudioVideo}
          hideDownloadInPlaylist={false}
          songList={hooksList}
          scrollContainer={this.scrollContainer}
        // setHandleAudioVideo={(value: any) => this.setHandleAudioVideo(value) }
        // setHandlePlay={(value: any) => this.setHandlePlay(value)}

        />
      );
    }

    const screenTab=()=> {
      if(token) {
        if(Top100List?.length === 0 && loading) {
        return <LoadingScreen />
        }
       else 
      {
      return  <Videos/>
      }
      
      } else {
        if(playList?.length === 0 && loading) {
          return (
            <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
              <img src={nxzloader} style={{ width: "5%" }} />
            </Row>
          )
        } else {
          return (
            <Songs
              listOfSongs={playList.slice(0, itemsToShow)}
              handlePlayList={this.handleRedirectPlaylist}
              name="Playlists"
              setDialogText={this.setDialogText}
              songIndex={songIndex}
              setOpen={this.setOpen}
              playlistData={{}}
              hideDownloadInPlaylist={hideDownloadInPlaylist}
              songList={playList}
          scrollContainer={this.scrollContainer}

            />
          )
        }
      }
    }
      

    const handleVerseList=()=>{
      return  versesList?.length === 0 && loading ? (
        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
          <img src={nxzloader} style={{ width: "5%" }} />
        </Row>
      ) : (
        <Songs
          listOfSongs={versesList.slice(0, itemsToShow)}
          playPlaylist={isPlaylist}
          name="Verses"
          playlistData={playlistData}
          songIndex={songIndex}
          setDialogText={this.setDialogText}
          setOpen={this.setOpen}
          handleplay={handleplay}
          handleAudioVideo={this.state.handleAudioVideo}
          hideDownloadInPlaylist={false}
          songList={versesList}
          scrollContainer={this.scrollContainer}

        // setHandleAudioVideo={(value: any) => this.setHandleAudioVideo(value) }
        // setHandlePlay={(value: any) => this.setHandlePlay(value)}

        />
      )
    }
    const screen = () => {


      switch (active) {
        case "":
        case 0:
          return handleBeatsList()
        case 1:
          return handleHookList()
        case 2:
          return handleVerseList()
        case 3:
          return  songsList?.length === 0 && loading ? (
            <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
              <img src={nxzloader} style={{ width: "5%" }} />
            </Row>
          ) : (
            <Songs listOfSongs={songsList.slice(0, itemsToShow)}
              playPlaylist={isPlaylist}
              name="Songs"
              playlistData={playlistData}
              songIndex={songIndex}
              setDialogText={this.setDialogText}
              setOpen={this.setOpen}
              handleplay={handleplay}
              handleAudioVideo={this.state.handleAudioVideo}
              hideDownloadInPlaylist={false}
            // setHandleAudioVideo={(value: any) => this.setHandleAudioVideo(value) }
            // setHandlePlay={(value: any) => this.setHandlePlay(value)}

            />
          )
        case 4:
          return <> {screenTab()} </>;

        case 5:
          return playList?.length === 0 && loading ? (
            <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
              <img src={nxzloader} style={{ width: "5%" }} />
            </Row>
          ) : (
            <Songs listOfSongs={playList.slice(0, itemsToShow)}
              handlePlayList={this.handleRedirectPlaylist}
              name="Playlists"
              setDialogText={this.setDialogText}
              songIndex={songIndex}
              setOpen={this.setOpen}
              playlistData={{}}
              hideDownloadInPlaylist={hideDownloadInPlaylist}

            />
          )
        default:
          return null;
      }
    }
    

    return (
      <>
        <div
          className="chartMain">
          <ChartTemplate isPlaylist={isPlaylist} active={active} scrollContainer={this.scrollContainer}>
            <h1 style={{ marginBottom: "0px", fontFamily: "Nunito" }}>Charts</h1>
            <div className="chats-header ">
              <TabList active={active} callback={this.callback} />

              <div style={{ position: "relative" }}>
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}

                  getPopupContainer={(trigger: any) => trigger.parentNode}
                >
                  <div
                    className="ant-dropdown-link filter_design"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img
                      src={Filter_black}
                      style={{ height: "10px", width: "11px" }}
                      alt=""
                    />
                    <p style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins" }}>{filterValue}</p>
                    <img
                      src={triangle}
                      style={{ height: "10px", width: "11px" }}
                      alt=""
                    />
                  </div>
                </Dropdown>
              </div>
            </div>

            {/* End of tab */}
            {screen()}
          </ChartTemplate>
          {/* </Box> */}

          {/* </Content>
          </Layout> */}

        </div>
        {open && <LoginAlert open={open} handleClose={this.handleClose} dialogText={dialogText} />}

        {alertPopup && <LoginAlert open={open} handleClose={this.handleClose} dialogText={dialogText} />}
      </>
    );
  };
}

// export default withRouter(Charts as React.ComponentType<any>);
// Customizable Area End
